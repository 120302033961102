import React from 'react';
import { Layout } from '@/common/components/layout';
import { SEO } from '@/common/components/seo';
import { useTranslation } from '@/common/hooks';
import { PayoutWalletApprove } from '@/profile/components/payout-wallet/payout-wallet-approve';
import { jsx as ___EmotionJSX } from "@emotion/core";

var PayoutWalletApprovePage = function PayoutWalletApprovePage() {
  var _useTranslation = useTranslation(),
      t = _useTranslation.t;

  return ___EmotionJSX(Layout, null, ___EmotionJSX(SEO, {
    title: t('wallet.title'),
    meta: [{
      name: 'robots',
      content: 'noindex'
    }]
  }), ___EmotionJSX(PayoutWalletApprove, null));
};

PayoutWalletApprovePage.displayName = "PayoutWalletApprovePage";
export { PayoutWalletApprovePage as default };